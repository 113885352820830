<template>
  <div class="mcr-iframe-video">
    <div class="inner" style="">
      <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" :src="src"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    src: String,
  },
  name: 'McrIframeVideo',
};
</script>

<style lang="scss" scoped>
.mcr-iframe-video {
  clear: both;
  .inner {
    padding-bottom: 56.25%;
    position: relative;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-width: 0;
    }
  }
}
</style>
